import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";

// Configs
import multiContentSlider from "../../../utilities/multiContentSlider";
import {GetAreaGuides} from "../../../queries/common_use_query";

// Styles
import './AreaGuides.scss';

// LayoutHelpers
import Layout01 from "../../../layout-helpers/Layout01";

const AreaGuides = ( { areaGuidesData } ) => {

    const { loading, error, data } = GetAreaGuides();

    const { Title, ...otherProperties } = areaGuidesData;

    const sectionHeaderData = {
        Title: "Featured Areas",
        // Link: {
        //     FullText: 'View All Area Guides',
        //     ShortText: 'View All',
        //     Href: '#'
        // }
    }

    return (
        <Layout01
            className="area-guides-wrapper"
            id="areaGuides"
            sectionHeaderData={ sectionHeaderData }
            cardType="areaCard"
            areaGuides={data?.areaGuides}
            layout={ {
                lg: 4,
                sm: 6,
                xs: 12,
            } }
            responsiveShowHide
            { ...otherProperties }
        />
    )
}

export default AreaGuides
